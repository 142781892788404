<template>
  <div>
    <van-form class="form" input-align="right">
        <div class="title"> <div></div> 咨询</div>
        <van-field name="问题来源:" label="问题来源:" v-model="dataForm.guideTitle" readonly />
        <van-field name="咨询问题:" label="咨询问题:" v-model="dataForm.questionTitle" readonly />
        <van-field name="问题时间:" label="问题时间:" v-model="dataForm.questionTime" readonly />
        <van-cell title="问题描述:" :border="false" />
        <van-field input-align="left" type="textarea" autosize maxlength="100" show-word-limit v-model="dataForm.questionContent" readonly />
        <div style="padding: 0 15px">
            <upload-file
                :fileType="'image'"
                :fileList="proFileList"
                :uploadPath="uploadPath"
                :delBtnShow="false"
                :uploadBtnShow="false"
            >
            </upload-file>
        </div>
    </van-form>
    <van-form class="form" input-align="right">
        <div class="title"><div></div>回复</div>
        <van-field v-if="visible" name="回复时间" label="回复时间:" v-model="dataForm.answerTime" readonly />
        <van-cell title="回复内容:" :border="false" />
        <van-field input-align="left" :readonly="visible" v-model="dataForm.answerContent" placeholder="请输入回复描述" />
        <div style="padding: 0 15px;">
            <upload-file
                :fileType="'image'"
                :fileList="fileList"
                :uploadPath="uploadPath"
                @filesUpload="upload"
                @delFile="delFile"
                :delBtnShow="!visible"
                :uploadBtnShow="!visible"
            >
            </upload-file>
        </div>
        
    </van-form>
    <div class="btns" v-if="!visible">
        <van-button type="info" class="btn" @click="onSubmit">回复</van-button>
    </div>
  </div>
</template>

<script>
import uploadFile from '@/components/upload/uploadFile'
export default {
    components: {
        uploadFile
    },
    data() {
        return {
            id: '',
            dataForm: {
                guideId: '',
                guideTitle: '',
                questionTitle: '',
                questionTime: '',
                questionContent: '',
                answerTime: '',
                answerContent: ''
            },
            fileMessages: [],
            proFileList: [],
            removeIds: [],
            fileList: [],
            uploadPath: 'files/commonApp/guide/faqs/temporary',
            visible: false
        }
    },
    created() {
        this.id = this.$route.query.id
        this.getInfo(this.$route.query.id)
    },
    methods: {
        // 获取咨询详情
        getInfo(id) {
            this.$http({
                url: this.$http.adornUrl('/wxapp/commonApp/guide/faqs/info'),
                method: 'GET',
                params: this.$http.adornParams({
                    id
                })
            }).then(({data}) => {
                if(data && data.code === 0) {
                    this.dataForm.guideTitle = data.commonAppGuideFaqs.guideTitle
                    this.dataForm.questionTitle = data.commonAppGuideFaqs.questionTitle
                    this.dataForm.questionTime = data.commonAppGuideFaqs.questionTime
                    this.dataForm.questionContent = data.commonAppGuideFaqs.questionContent
                    this.dataForm.answerTime = data.commonAppGuideFaqs.answerTime
                    this.dataForm.answerContent = data.commonAppGuideFaqs.answerContent
                    this.dataForm.guideId = data.commonAppGuideFaqs.guideId
                    this.visible = data.commonAppGuideFaqs.hasAnswer === 1
                    if(data.commonAppGuideFaqs.faqsFileList) {
                        this.proFileList = data.commonAppGuideFaqs.faqsFileList.filter(item => !item.isSolve)
                        this.proFileList = this.proFileList.map(item => {
                            return {
                                fileType: item.type,
                                relativePath: item.filePath,
                                ...item
                            }
                        })
                    }
                    if(data.commonAppGuideFaqs.faqsFileList) {
                        this.fileList = data.commonAppGuideFaqs.faqsFileList.filter(item => item.isSolve)
                        if(this.fileList.length > 0) {
                            this.fileList = this.fileList.map(item => {
                                return {
                                    fileType: item.type,
                                    relativePath: item.filePath,
                                    ...item
                                }
                            })
                        }
                    }
                }
            })
        },
        // 上传图片
        upload(res) {
            this.fileMessages = res
        },
        delFile(files) {
            this.removeIds.push(files)
        },
        onSubmit() {
            this.$toast.loading({message: '提交中...'})
            this.$http({
                url: this.$http.adornUrl('/wxapp/commonApp/guide/faqs/update'),
                method: 'POST',
                data: this.$http.adornData({
                    id: parseInt(this.id),
                    guideId: this.dataForm.guideId,
                    answerContent: this.dataForm.answerContent,
                    newFileMessages: this.fileMessages,
                    answerUserId: this.$userId
                })
            }).then(({data}) => {
                this.$toast.clear()
                if(data && data.code === 0) {
                    this.$toast.success({
                        message: '回复成功',
                        duration: 1500,
                    })
                    setTimeout(() => {
                        this.getInfo(this.id)
                    },1500)
                }else {
                    this.$toast.fail(data.msg)
                }
            })
        }
    }
}
</script>

<style scoped>
.form >>> .van-cell--borderless {
    padding-bottom: 0;
}
</style>
<style lang="scss" scoped>
.title {
    width: 100%;
    height: 60px;
    font-size: 34px;
    line-height: 60px;
    padding: 0 30px;
    font-weight: 500;
    background-color: white;
    display: flex;
    align-items: center;
    div {
        display: inline-block;
        width: 10px;
        height: 40px;
        border-radius: 6px;
        background-color: #007AFF;
        margin-right: 10px;
    }
}
.form {
    background-color: white;
    margin-top: 20px;
}
.btns {
    .btn {
        width: 100%;
        height: 90px;
        border-radius: 8px;
    }
}
</style>